<script lang="ts" setup>import { ref as _ref } from 'vue';

// https://vue-macros.dev/zh-CN/features/reactivity-transform.html
let count = _ref(0);

// console.log(count);

function increment() {
  count.value++;
}
</script>

<template>
  <button class="green" @click="increment">ReactivityTransform: {{ count }}</button>
</template>
